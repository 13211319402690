/* VERSION DESIGN - Non Material UI Styles for WILSON SHUFFLE APP build */

/* General styles
---------------------------------------------------------------------------------------------- */

/* Colour Pallet
----------------------------------------------------------------------------------------------
Mono:
#ffffff - White
#000000 - Black

Primary:
#ffe6e6 - Very Light (pink)
#ff9f9f - Light (pink/red)
#ff0000 - Normal (red)
#9c1937 - Dark (maroon)
#6e0021 - Very Dark (maroon)

Neutrals:
#f6f6f6 - Off White
#e3e3e3 - Grey Neutral
#bbbaba - Grey light
#979797 - Grey Mid
#525252 - Grey dark

Semantic:
#bbe8d2 - Success Very Light (green)
#55ddb4 - Success Light (green)
#009273 - Success Normal (green)
#026651 - Success Dark (green)
#014139 - Success Very Dark (green)

#ffe574 - Warning Very light (orange)
#fbcf97 - Warning Light (orange)
#f99d21 - Warning Normal (orange)
#d07c0b - Warning Dark (orange)
#9b5900 - Warning Very Dark (orange)

#fcc7ba - Error Very Light (pink)
#f47a5c - Error Light (pink)
#ef3f1c - Error Normal (red)
#b12d03 - Error Dark (red)
#792c00 - Error Very Dark (red)

Shades:
#000000 - 10% / 30% / 50% / 70% / 90%

tints:
#ffffff - 10% / 30% / 50% / 70% / 90%

/* Font Sizes - converted from px to rem
----------------------------------------------------------------------------------------------
8px		- 	0.5rem
9px		- 	0.563rem
10px	- 	0.625rem
11px	- 	0.688rem
12px	- 	0.75rem
13px	-	0.813rem
14px	-	0.875rem
15px	-	0.938rem
16px	-	1rem
17px	-	1.063rem
18px	-	1.125rem
19px	-	1.188rem
20px	-	1.25rem
---------------------------------------------------------------------------------------------- */

/* Webfonts & weights
---------------------------------------------------------------------------------------------- */

/* Untitled Sans */

/* US - Regular */

@font-face {
    font-family: 'Untitled Sans';
    src: url('assets/fonts/UntitledSans-Regular.woff2') format('woff2'),
    url('assets/fonts/UntitledSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('assets/fonts/UntitledSans-RegularItalic.woff2') format('woff2'),
    url('assets/fonts/UntitledSans-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

/* US - Medium */

@font-face {
    font-family: 'Untitled Sans';
    src: url('assets/fonts/UntitledSans-Medium.woff2') format('woff2'),
    url('assets/fonts/UntitledSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('assets/fonts/UntitledSans-MediumItalic.woff2') format('woff2'),
    url('assets/fonts/UntitledSans-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

/* US - Bold */

@font-face {
    font-family: 'Untitled Sans';
    src: url('assets/fonts/UntitledSans-Bold.woff2') format('woff2'),
    url('assets/fonts/UntitledSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('assets/fonts/UntitledSans-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/UntitledSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

/* Eina */

@font-face {
    font-family: "Eina01";
    src: url('assets/fonts/Eina01-SemiBold.woff2') format('woff2'),
    url('assets/fonts/Eina01-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Eina01";
    src: url('assets/fonts/Eina01-SemiboldItalic.woff2') format('woff2'),
    url('assets/fonts/Eina01-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}


/* ---------------------------------------------------------------------------------------------- */


/* Base Styles */

/*

* {
	margin: 0;
	padding: 0;
	outline: 0;
}

*/

html, body {
    height: 100%;
}

body, h1, h2, h3, h4, h5, h6,  p, ul, li, form {
    margin: 0px;
    padding: 0px;
}

body {
    font-family: 'Untitled Sans', sans-serif;
}


/* Typography
---------------------------------------------------------------------------------------------- */

h1, h2, h3, h4, h5, h6 {
    font-family: 'Eina01', sans-serif;
    color: #000000;
}

p { /* Text 4 */
    font-size: 1rem;
    line-height: 140%; /* 22px */
    color: #000000;
}

p.centered-text P {
    text-align: center;
}

p strong, strong, th {
    font-weight: 500;
}

a {
    color: #ff0000;
}

h1 {
    /* CHECK THIS??? FROM OLD BUILD???
    font-size: 1.25rem;
    margin: 0;
    line-height: 1.2rem; */
}

h1 {
    font-size: 40px;
    line-height: 120%;
    color: white;
}

.centered-heading {
    text-align: center;
}

.settings-h1 {
    padding-top: 65px;
}

p.enabled,
.MuiFormControlLabel-root.custom-switch .MuiTypography-root {
    color: #009273;
    font-size: 1.125rem;
    font-weight: 500;
    margin-right: 10px;
}

.MuiFormControlLabel-root.custom-switch-disabled .MuiTypography-root {
    color: #525252;
    font-size: 1.125rem;
    font-weight: normal;
    margin-right: 10px;
}

.modal-checkboxes.custom-radio-group {
    display: block;
    margin-left: 10px;
    margin-bottom: 15px;
}

.custom-radio-group .MuiTypography-root {
    font-size: 0.875rem;
}

.modal-checkboxes.custom-radio-group .MuiFormControlLabel-root {
    padding-bottom: 5px;
}

.custom-radio-group .MuiRadio-root {
    padding: 4px;
}

table {
    border-collapse: collapse;
}


/* Inputs & Buttons
---------------------------------------------------------------------------------------------- */

.MuiButtonBase-root.MuiButton-root .MuiButton-label {
    font-weight: 500 !important;
}

button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    /* Wilson Global/Primary/Primary normal */
    background: #FF0000;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    line-height: 120%;
    border: 0 none;
    font-weight: 500 !important;
}

button.disabled {
    background-color: #e3e3e3;
    color: #979797;
}

.buttonIcon {

}

.shuffleInputIcon .MuiOutlinedInput-input {
    padding-left: 7px !important;
}

.input-clock-icon {
    width: 22px;
    height: 22px;
}

.input-calendar-icon {
    width: 24px;
    height: 24px;
}

.child-checkbox {
    margin-left: 15px !important;
}

label.checked .MuiTypography-root {
    font-weight: 500 !important;
}

.outlined-mono {
    border: 1px solid #ffffff !important;
    padding: 6px 9px !important;
    text-transform: none  !important;;
    color: white !important;
}

.outlined-mono .MuiButton-label {
    font-family: 'Untitled Sans';
    text-transform: none  !important;;
    color: white !important;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 0;
    padding-left: 0;
    padding-right: 0;
}

.MuiButton-outlined.MuiButton-outlinedSizeSmall .MuiButton-label {
    color: #525252 !important;
}

/* .reservedButtonSmlOL {
	font-family: 'Untitled Sans';
	text-transform: none !important;
	color: #525252 !important;
	background-color: #ffffff;
	font-size: 0.875rem !important;
	font-weight: bold !important;
	border-radius: 4px;
	letter-spacing: 0;
	padding-right: 0;
	padding-left: 0;
	max-width: 105px;
	margin-right: 10px;
	margin-top: 8px !important;
} */


/* padding dense override */

.MuiFormControl-marginDense .MuiOutlinedInput-input,
.shuffle-select .MuiSelect-select.MuiSelect-outlined {
    padding: 10.5px;
}

/* Icon based links */

.copy-link {
    display: block;
    height: 30px;
    text-decoration: none;
    font-size: 1rem;
    line-height: 30px;
    font-weight: 500;
    text-align: right;
    margin-top: 18px;
    /* display: flex;
    align-items: center;
    width: 200px;
    margin-top: 8px;
    margin-left: 35px; */
}

.copy-link:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-ui-icon-copy-red.svg');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 22px;
    width: 22px;
    margin-right: 7px;
}


/* Header
---------------------------------------------------------------------------------------------- */

#header-wrapper {
    width: 100%;
    height: 90px;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 0;
    height: auto;
    position: fixed;
    top: 0;
    z-index: 3;
}

#header-wrapper.login-header {
    text-align: center;
}

#header {
    margin: 0 auto;
    padding: 0;
    height: 90px;
    max-width: 1440px;
    color: white;
    display: flex;
    align-items: center;
    /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1); */
}

/* Header Logo styles */

.logo-holder.row {
    width: 30%;
    display: flex;
    align-items: center;
}

.self-reg-nav .logo-holder.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

a#wilson-parking-logo {
    display: inline-block;
    padding: 0;
    margin: 0;
}

a#wilson-parking-logo img {
    height: 30px;
    width: auto;
    padding: 0;
    margin: 0;
}

.main-nav a#shuffle-logo {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 20px 0 0;
    height: 40px;
}

.main-nav a#shuffle-logo img {
    height: 22px;
    width: auto;
    padding: 0;
    margin: 0;
}

.main-nav a#aust-resources-logo {
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    margin: 0;
    height: 40px;
    border-left: 1px solid #e3e3e3;
}

.main-nav a#aust-resources-logo img {
    height: 32px;
    width: auto;
    padding: 0;
    margin: 0;
}

.main-nav a#your-logo {
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    margin: 0;
    height: 40px;
    border-left: 1px solid #e3e3e3;
}

.main-nav a#your-logo img {
    height: 40px;
    width: auto;
    padding: 0;
    margin: 0;
}

/* Header Nav styles */

#header.main-nav {
    padding: 0px;
    max-width: 1360px;
    position: relative;
}

#header.login-nav {
    justify-content: center;
    padding: 0px;
    max-width: 1440px;
    position: relative;
}

.nav-holder.row {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

a#user-loggedin {
    display: block;
    background-color: #ff0000;
    color: white;
    text-align: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    line-height: 48px;
    text-decoration: none;
    margin-left: 38px;
}

ul#shuffle-main-nav {
    display: flex;
    justify-content: flex-end;
    list-style: none;
}

ul#shuffle-main-nav li {
    position: relative;
}

ul#shuffle-main-nav li a {
    font-family: "Untitled Sans", sans-serif;
    display: block;
    padding: 10px 17px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: black;
    height: 100%;
}

ul#shuffle-main-nav li.selected > a,
ul#shuffle-main-nav li:hover > a,
ul#shuffle-main-nav li:active > a,
ul#shuffle-main-nav li.selected > a:hover,
ul#shuffle-main-nav li.selected > a:active {
    color: #ff0000;
}

ul#shuffle-main-nav li.has-children > a {
    padding-right: 23px;
}

ul#shuffle-main-nav li.has-children > a::after {
    display: block;
    content: "";
    background-image: url("assets/svg/ws-sml-icon-chevrondown.svg");
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-top: -14px;
    margin-left: 100%;
}

ul#shuffle-main-nav li.has-children.selected > a::after,
ul#shuffle-main-nav li:hover > a::after,
ul#shuffle-main-nav li:active > a::after {
    background-image: url("assets/svg/ws-sml-icon-chevrondown-red.svg");
}

ul.dropdown {
    border-radius: 0.5rem;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    top: 130%;
    left: -50%;
    display: none;
    list-style: none;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    padding: 0.7rem 1rem .8rem;
}

.user ul.dropdown {
    top: 100%;
}

ul.dropdown.pull-left {
    left: -120%;
}

ul#shuffle-main-nav li:hover > ul,
ul#shuffle-main-nav li:focus-within > ul,
ul.dropdown:hover,
ul.dropdown:focus,
.user:hover ul,
.user:focus ul {
    visibility: visible;
    opacity: 1;
    display: block;
}

ul.dropdown li {
    clear: both;
    width: 100%;
}

body ul.dropdown li a {
    display: flex !important;
    padding: .5rem !important;
    font-weight: normal !important;
    white-space: nowrap;
    color: black;
    text-decoration: none;
}

body ul#shuffle-main-nav ul.dropdown li:hover,
body ul#shuffle-main-nav ul.dropdown li:active {
    color: black;
}

body ul#shuffle-main-nav ul.dropdown li a:hover,
body ul#shuffle-main-nav ul.dropdown li a:active {
    font-weight: 500 !important;
    color: black;
}

ul.dropdown li a img {
    /* max-width: 20px;
    max-height: 20px;
    width: 30px;
    padding-right: 10px; */
}

ul.dropdown li a img.manage-parkers-icon {
    width: 25px;
    padding-right: 10px;
}

ul.dropdown li a img.assign-parking-icon {
    width: 17px;
    padding-left: 4px;
    padding-right: 14px;
}

ul.dropdown li a img.products-capacities-icon {
    width: 25px;
    padding-right: 10px;
}

ul.dropdown li a img.block-out-dates-icon {
    width: 25px;
    padding-right: 10px;
}

ul.dropdown li a img.book-for-guest-icon {
    width: 23px;
    padding-left: 1px;
    padding-right: 11px;
}

ul.dropdown li a img.transactions-icon {
    width: 16px;
    padding-left: 5px;
    padding-right: 14px;
}

ul.dropdown li a img.manage-all-users-icon {
    width: 25px;
    padding-right: 10px;
}

ul.dropdown li a img.self-reg-menu-icon {
    width: 25px;
    padding-right: 10px;
}

ul.dropdown li a:hover,
ul.dropdown li a:focus {
    background: #f6f6f6;
    text-shadow: .7px 0 black;
}

.user {
    position: relative;
}

.user a {
    flex-direction: column;
    /* text-shadow: .5px 0 black; IS THIS REQUIRED??? */
}

.user a span {
    margin-top: .5em;
    text-shadow: none;
    font-size: 14px;
}

.user li:last-child a {
    font-size: 14px;
    margin-top: 1em;
}

.user ul.dropdown.pull-left {
    left: -130%;
}


/* Top Nav Styles */

#top-nav-header {
    background-color: #f6f6f6;
    height: 40px;
    display: flex;
    align-items: center;
}

.top-nav-holder {
    display: flex;
    width: 1360px;
    max-width: 1360px;
    margin: 0 auto;
}

ul#shuffle-home-nav {
    display: flex;
    width: 20%;
    justify-content: flex-start;
    list-style: none;
    max-width: 1440px;
    margin: 0 auto;
}

ul#shuffle-top-nav {
    display: flex;
    width: 80%;
    justify-content: flex-end;
    list-style: none;
    max-width: 1440px;
    margin: 0 auto;
}

ul#shuffle-top-nav li a,
ul#shuffle-home-nav li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 20px;
    font-weight: normal;
    color: black;
}

ul#shuffle-top-nav li.selected a,
ul#shuffle-top-nav li a:hover,
ul#shuffle-top-nav li a:active,
ul#shuffle-home-nav li.selected a,
ul#shuffle-home-nav li a:hover,
ul#shuffle-home-nav li a:active {
    background-color: #ffffff;
}


/* Content containers
---------------------------------------------------------------------------------------------- */

#wrapper {
    height: 100%;
    background-color: #e5e5e5;
    background-image: url('assets/images/dashboard-bg-tile.png');
    background-position: top left;
    background-repeat: repeat-x;
}

#main.section-entry {
    background-color: #000000;
    min-height: 300px;
    width: 100%;
    margin-top: 130px;
}

#main {
    /* width: 100%;
    height: 100%;
    margin-top: 130px; */
}

#main.fs-bg-image,
.makeStyles-loginBG-13 {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('assets/images/shuffle-login-bg-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.loginBG {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('assets/images/shuffle-login-bg-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100vw !important;
    height: 100vh !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    max-width: 100vw !important;
}

.adminBG {
    background-color: #ffffff;
    width: 100vw !important;
    /* height: 100vh !important; */
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    max-width: 100vw !important;
}

.modalOverlayBG {
    background-color: rgba(0,0,0,0.5);
    width: 100vw !important;
    height: 100vh !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    max-width: 100vw !important;
    z-index: 4;
    position: fixed;
}

.modalOverlayBG .MuiButton-root {
    margin-top: 0 !important;
}

.scrolling-checkbox-wrapper {
    height: 400px;
    padding: 40px 40px 0 40px;
    overflow-x: clip;
    overflow-y: scroll;
}

.scrolling-checkbox-footer {
    background-color: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 32px;
    width: 785px !important;
    border-radius: 0 0 4px 4px;
    margin: 0 0 0 0 !important;
}

#main.dashboard {
    max-width: 1130px;
    margin: 130px auto 0 auto;
}

#main.section-entry {

}

.viewport-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    height: 100%;
}

/* Dashboard */

.page-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1130px;
    height: 128px;
    background-color: #000000;
}

.dashboard-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1130px;
}

.page-header h1 {
    color: white;
    font-size: 2.5rem;
    line-height: 48px;
    font-weight: normal;
    padding-top: 35px;
}

.dashboard-panel {
    background-color: white;
    border-radius: 4px;
    float: left;
    min-height: 200px;
    padding: 40px;
}

.dashboard-panel.full-width {
    width: 100%;
}

.dashboard-panel.one-third {
    width: calc(30% - 80px);
    margin-right: 5%;
    margin-top: 30px;
}

.dashboard-panel.one-third:last-child {
    margin-right: 0;
}

/* Section entry page styles */

.page-content {
    margin-top: -146px
}

.page-content-wrapper {
    width: 100%;
    /* width: calc(100% - 32px);
    background-color: black;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px; */
}

.page-content-wrapper.dashboard-section {
    background-color: #f6f6f6;
}

.page-content-v2 {
    margin-top: 175px;
}

.page-content-v2 .sidebar {
    background-color: black;
}

.page-content.no-header {
    margin-top: -260px
}

.section-card {
    background-color: white;
    border-radius: 4px;
    padding: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 283px;
    margin: 0 10px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.section-card h2 {
    font-size: 1.75rem;
    font-weight: normal;
    padding-bottom: 8px;
    letter-spacing: -0.01em;
}

.section-card p {
    font-size: 1rem;
    line-height: 1.375rem;
    padding-bottom: 32px;
    color: #525252;
    display: flex;
    /* height: 60px; /* POTENTIALLY A BETTER WAY TO DO THIS */
}

/* Assigned landing page icons */

.icon-box-wrapper {
    display: block;
    /* background-color: #dddddd; */
    width: 120px;
    height: 110px;
}

.section-card img.assigned-manage-parkers {
    width: 82px;
    padding: 9px 0 0px 19px;
}

.section-card img.assigned-assign-parking {
    width: 55px;
    padding: 0;
}

/* Settings landing page icons */

.section-card img.settings-manage-users {
    width: 82px;
    padding: 9px 0 0px 19px;
}

.section-card img.settings-self-reg {
    width: 82px;
    padding: 0px 0 0 20px;
}

/* On Demand landing page icons */

.section-card img.on-demand-prods-capacities {
    width: 82px;
    padding: 0 0 0 18px;
}

.section-card img.on-demand-transactions {
    width: 52px;
    padding: 0 0;
}

.section-card img.on-demand-block-dates {
    width: 82px;
    padding: 0 0 0 18px;
}

/* Guest landing page icons */

.section-card img.guest-book-guest {
    width: 76px;
    padding: 0 0 0 22px;
}

.section-card img.guest-transactions {
    width: 52px;
    padding: 0 0 0 0;
}

.section-card button {
    max-width: 200px;
    margin-top: auto;
}

.section-card img {
    max-height: 85px;
    margin-bottom: 20px;
}

#main.section-entry .page-header {
    height: 156px;
}

#main.section-entry .page-header h1 {
    text-align: center;
    padding-top: 65px;
}


/* Login Section Styles
---------------------------------------------------------------------------------------------- */

.warning-dialogue {
    display: block;
    position: absolute;
    background-color: #ef3f1c;
    color: white;
    z-index: 5;
    padding: 14px 16px;
    border-radius: 4px;
    width: 558px;
    top: 10px;
    left: 50%;
    margin-left: -279px;
}

.warning-dialogue p.warning-text,
p.warning-text,
.warning-dialogue p.success-text,
p.success-text {
    color: #ffffff;
    display: inline-flex;
    font-size: 1rem;
    line-height: 27px;
}

.warning-dialogue p.warning-text:before,
p.warning-text:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-ui-icon-warning-critical-wht.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 24px;
    width: 24px;
    margin-right: 7px;
}

.warning-dialogue p.success-text:before,
p.success-text:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-tick-2-wht.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 10px;
    width: 14px;
    margin-right: 15px;
    margin-top: 7px;
}

.welcome-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    /* width: 447px;
    height: 553px;
    position: absolute;
    left: 496px;
    top: 130px; */
}

#login-modal {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    position: relative;
    left: 0px;
    top: 64px;
    width: 367px;
}

.login-modal-styles h3.login-h3 {
    /* WEB/H3 */
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 120%;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 20px;
}

.form-modal-styles h3.modal-h3 {
    /* WEB/H3 */
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 120%;
    color: #000000;
    margin-bottom: 20px;
}

.form-modal-styles h2.modal-h2 {
    /* WEB/H3 */
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 120%;
    color: #000000;
    margin-bottom: 10px;
}

.form-modal-styles h3.modal-h3.larger-text {
    font-size: 1.75rem;
}

.form-modal-styles h3.modal-h3.no-margin {
    margin-bottom: 0;
}

.form-modal-styles p {
    color: #525252;
}

.form-modal-styles p.calendar-subtitle {
    font-size: 1rem;
    line-height: 140%;
    color: #979797;
}

.login-modal-styles h4.login-h4 {
    /* WEB/H4 */
    font-weight: normal;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 8px;
    margin-top: 5px;
}

.login-modal-styles .shuffle-login-logo {
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.login-modal-styles p.reset-pass-message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #525252;
    margin-bottom: 40px;
}

.login-modal-styles a.password-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #FF0000;
    display: block;
    margin-left: -20px;
    margin-top: 12px;
}

.login-modal-styles a.password-link::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-right: 5px;
}

.login-modal-styles a.password-link.login-back::before {
    background-image: url('assets/svg/ws-sml-icon-chevronleft-red.svg');
}

.manage-users-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 120%;
    text-align: center;
    color: #FF0000;
    display: block;
    margin-top: 10px;
}

a.manage-users-link::after {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-right: 5px;
}


.login-modal-styles p.input-label,
.form-modal-styles p.input-label,
.self-reg-modal-styles p.input-label,
p.input-label {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    /* margin-bottom: 7px; */
}

.login-modal-styles p.input-error-label,
.form-modal-styles p.input-error-label,
.self-reg-modal-styles p.input-error-label,
p.input-error-label {
    display: block;
    width: 100%;
    font-size: 0.75rem;
    text-align: left;
    font-weight: 400;
    color: #ff0000;
    /* margin-bottom: 7px; */
}


.form-modal-styles p.checkbox-label {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    margin-bottom: 10px;
}

.form-modal-styles p.small-heading {
    width: 100%;
    font-size: 0.875rem;
    text-align: left;
    font-weight: 500;
    margin-bottom: 15px;
}

.login-modal-styles p.password-tips {
    display: block;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 5px;
    margin-top: 15px;
}

.login-modal-styles ul.password-tips-list {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 20px;
}

.login-modal-styles ul.password-tips-list.password-accepted {
    color: #009273;
}

.login-modal-styles ul.password-tips-list li {
    list-style-image: url(assets/svg/ws-sml-icon-tick-grey.svg);
    padding: 5px 0 5px 10px;
    text-align: left;
}

.login-modal-styles ul.password-tips-list.password-accepted li {
    list-style-image: url(assets/svg/ws-sml-icon-tick-grn.svg);
}

.login-modal-styles button,
.self-reg-modal-styles button {
    width: 100%;
    margin: 20px 0 10px 0;
}

.login-modal-styles.base-button button,
.self-reg-modal-styles.base-button button {
    margin: 20px 0 0 0;
}

.modal-disclaimer-text {
    text-align: center;
    font-size: 0.75rem;
}

/* Checkbox overrides */

.MuiCheckbox-root {
    padding: 4px 9px !important;
}

.MuiCheckbox-root .MuiSvgIcon-root {
    font-size: 1.3rem;
}

.MuiTypography-root {
    font-size: 0.875rem !important;
}

.MuiCheckbox-root {
    color: #979797 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #ff0000 !important;
}


/* Self Reg Section Styles
---------------------------------------------------------------------------------------------- */

.selfRegBG {
    background: #dce5f7;
    width: auto !important;
    /* height: 100vh !important; */
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    max-width: 100vw !important;
}

.self-reg-modal-styles h2.self-reg-h2 {
    /* WEB/H2 */
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 120%;
    text-align: left;
    color: #000000;
    margin-bottom: 12px;
}

.self-reg-modal-styles h2.self-reg-h2.centered-h2 {
    text-align: center;
}

.self-reg-modal-styles h3.self-reg-h3 {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 120%;
    text-align: left;
    color: #000000;
    margin-bottom: 0px;
    margin-top: 30px;
}

.self-reg-sub-text {
    text-align: center;
    color: #525252;
    font-size: 1.25rem;
    padding: 0 0px;
}

.self-reg-required-text {
    color: #979797;
    font-size: 0.875rem;
}

.self-reg-email-text {
    font-size: 0.875rem;
    line-height: 120%;
    text-align: center;
    margin-top: 40px;
}

.self-reg-modal-styles a.resend-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #FF0000;
    display: block;
    margin-left: -20px;
    margin-top: 25px;
}

.self-reg-modal-styles a.resend-link::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-right: 5px;
}

.self-reg-icon {
    text-align: center;
}

.self-reg-icon-email {
    width: 80px;
    height: 55px;
    padding-top: 30px;
    padding-bottom: 50px;
}

.self-reg-icon-unavailable {
    width: 52px;
    height: 80px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.self-reg-icon-admin-review {
    width: 79px;
    height: 79px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.self-reg-icon-accepted {
    width: 79px;
    height: 79px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.justified-content {
    text-align: center;
}

.error-text {
    color: #ef3f1c;
    font-size: 0.75rem;
    padding-top: 3px;
}

.self-reg-modal-styles label.select-heading,
.self-reg-modal-styles .select-heading {
    padding-bottom: 0;
}

.select-error .MuiOutlinedInput-notchedOutline,
.shuffleInputError .MuiOutlinedInput-notchedOutline  {
    border-color: #ef3f1c !important;
}


/* On-Demand Section styles
---------------------------------------------------------------------------------------------- */

.on-demand-sub-text {
    font-size: 1rem !important;
    line-height: 120% !important;
}

.transaction-detail {
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 10px;
    padding-top: 20px;
}

.transaction-detail.first-item {
    padding-top: 0;
}

.transaction-detail.last-item {
    border-bottom: none;
    padding-bottom: 0;
}

.transaction-detail h5 {
    font-size: 1.125rem;
    line-height: 120%;
}

.transaction-link {
    text-decoration: none;
    font-weight: 500;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

table#transaction-detail-table {
    font-size: 1rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cancelled-alert-text {
    font-size: 0.875rem;
    line-height: 140%;
    color: #ff0000;
    padding-bottom: 10px;
}

.show-hide-wrapper {
    display: flex;
    align-content: center;
}

.show-hide-wrapper.expanded {
    padding-bottom: 30px !important;
}

.show-hide-subhead {
    font-size: 0.875rem;
    line-height: 140%;
}

.switch-card {
    padding: 20px;
    background: rgba(245, 242, 240, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
}

.show-details,
.hide-details {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    margin-left: auto;
}

.show-details:after,
.hide-details:after {
    display: inline-flex;
    content: "";
    background-size: 10px 6px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 6px;
    width: 10px;
    margin-left: 12px;
    margin-top: 0px;
}

.show-details:after {
    background-image: url("assets/svg/ws-sml-icon-chevrondown-red.svg");
}

.hide-details:after {
    background-image: url("assets/svg/ws-sml-icon-chevronup-red.svg");
}

.set-bays {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
}

.set-bays:after {
    background-image: url("assets/svg/ws-sml-icon-chevrondown.svg");
    display: inline-flex;
    content: "";
    background-size: 10px 6px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 6px;
    width: 10px;
    margin-left: 8px;
    margin-top: 3px;
}

.department-header {
    margin-top: 32px !important;
}

.department-header h4 {
    font-size: 1.25rem;
    line-height: 120%;
}

.enable-options {
    display: flex;
    align-items: center;
    margin-bottom: 18px !important;
}

.enable-options.single-dept {
    margin-top: 22px !important;
}

.enable-options.multi-dept {
    margin-top: 0 !important;
}

.enable-all,
.disable-all {
    font-size: 1rem;
    line-height: 120%;
    font-weight: 500;
    margin-right: 20px;
    color: #ff0000;
    text-decoration: none;
}

p.optional-limit {
    font-size: 0.75rem;
    line-height: 120%;
    text-align: right;
    margin-left: auto;
}

.parking-icon.earlybird-icon {
    width: 34px;
    margin-left: 8px;
    margin-right: 28px;
}

.parking-icon.hourly-icon {
    width: 28px;
    margin-left: 10px;
    margin-right: 32px;
}

.parking-icon.long-term-icon {
    width: 34px;
    margin-left: 7px;
    margin-right: 29px;
}

.MuiFormControlLabel-root.parking-switch .MuiTypography-root {
    color: #000000;
    font-size: 1rem !important;
    font-weight: normal;
    margin-left: 10px;
}

.flex-aligned {
    display: flex;
    align-content: center;
    justify-content: flex-end;
}

.content-panel.not-in-use {
    background-color: #f6f6f6;
    box-shadow: none;
}

.content-panel.not-in-use p,
.content-panel.not-in-use h3 {
    color: #979797;
}


/* Assigned Parking Section Styles
---------------------------------------------------------------------------------------------- */

.responsive-table-wrapper {
    margin-bottom: 26px;
}

#assigned-parking-table,
.assigned-parking-table {
    font-size: 0.875rem;
    color: #ffffff;
    width: 100%;
}

#assigned-parking-table td:first-child,
.assigned-parking-table td:first-child {
    padding: 5px 5px 5px 0;
}

#assigned-parking-table td,
.assigned-parking-table td {
    padding: 5px 0px 5px 0;
}

.assigned-parking-table .table-cell-left {
    text-align: left;
    width: 35%;
}

.assigned-parking-table .table-cell-right {
    text-align: left;
    width: 65%;
}

#bay-use-table {
    font-size: 0.875rem;
    color: #525252;
}

#bay-use-table td {
    padding: 2px 10px 2px 0;
}

h5.bay-user {
    font-size: 1.125rem;
    padding-bottom: 0px;
}

p.heading-subtitle {
    text-align: right;
    color: #525252;
}

.day-usage-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

ul.day-usage-menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: 100%;
    margin: 0 auto;
}

ul.day-usage-menu li {
    position: relative;
    margin: 0 2px;
    width: 14%;
}

ul.day-usage-menu li a {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.3);
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    color: #000000;
    margin: 0 auto;
}

ul.day-usage-menu li a.disabled {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #f6f6f6;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.3);
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    color: #bbbaba;
}

ul.day-usage-menu li a.enabled {
    display: block;
    width: 38px;
    height: 38px;
    background-color: #009273;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.3);
    text-decoration: none;
    font-size: 1.25rem;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    color: white;
}

ul.day-usage-menu li p {
    font-size: 0.75rem;
    text-align: center;
    padding-top: 12px;
}

.day-usage-options {
    display: flex;
    align-items: center;
}

.hourly-avails {
    display: flex;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    font-size: 0.75rem;
    color: #525252;
    padding: 9px 28px 9px 8px;
    background-image: url("assets/svg/ws-sml-icon-time-grey.svg");
    background-position: 93% center;
    background-repeat: no-repeat;
    margin-bottom: 4px;
    max-width: 80px;
}

span.hourly-avails:first-of-type {
    margin-top: 15px;
}

.info-icon {
    background-image: url("assets/svg/ws-sml-icon-info-white.svg");
    background-size: 2px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #979797;
    margin-right: 10px;
}

.info-icon:hover,
.info-icon:active {
    background-color: #000000;
}

.filter-starts,
.filter-expires {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
}

.filter-expires {
    margin-left: 20px;
}

.filter-link {
    font-size: 0.875rem;
    line-height: 140%;
    color: #000000;
    font-weight: 500;
    text-decoration: none;
}

.filter-link:active,
.filter-link:hover,
.filter-link.selected {
    color: #ff0000;
    text-decoration: underline;
}

.filter-link:after {
    display: inline-flex;
    content: '';
    background-image: url("assets/svg/ws-sml-icon-chevrondown.svg");
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 8px;
    width: 16px;
}

.sml-checkbox-label .MuiTypography-root {
    font-size: 0.75rem !important;
    color: #525252 !important;
}

.sml-checkbox-label.checked .MuiTypography-root {
    font-weight: 500;
}

.day-usage-options .bay-usage-key {
    margin-left: auto;
}

.content-panel h3.percentage-used {
    font-size: 2.25rem;
    color: #0085da;
}

.content-panel h3.percentage-remaining {
    font-size: 2.25rem;
    color: #000000;
}

.content-panel h3.percentage-shared {
    font-size: 2.25rem;
    color: #015e9a;
}

.content-panel p.percentage-used-by {
    font-size: 1rem;
    color: #525252;
}

.shared-usage-list-item {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.assigned-icon-block {
    display: block;
    max-width: 170px;
}

.assigned-icon-block h4 {
    font-size: 1.25rem;
    line-height: 120%;
    padding-bottom: 3px;
}

.assigned-icon-block p {
    font-size: 0.875rem;
    line-height: 120%;
    color: #525252;
}

.assigned-icon.reserved-icon {
    width: 34px;
    padding-bottom: 12px;
    margin-top: 14px;
}

.user-assigned-bays {
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    padding: 16px;
    margin-top: 20px;
}

.user-assigned-bays h5 {
    font-size: 1.125rem;
    line-height: 120%;
    padding-bottom: 3px;
}

.user-assigned-bays p {
    font-size: 0.875rem;
    line-height: 120%;
    color: #525252;
}

.user-assigned-bays .day-usage-wrapper {
    margin: 30px 0;
}

h4.content-panel-h4 {
    font-size: 1.25rem;
    line-height: 120%;
    padding-bottom: 5px;
}

.content-panel p.descriptive-text {
    font-size: 1rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 20px;
}

.calendar-placeholder {
    max-width: 335px;
}

.percentage-utilised-graphic {
    background-image: url("assets/svg/parking-utilisation-graphic.svg");
    background-size: 120px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 120px;
    height: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.percentage-utilised-graphic h6.percentage-utilised-header {
    font-size: 1.5rem;
    line-height: 120%;
    color: black;
}

.percentage-utilised-graphic p.percentage-utilised-text {
    font-size: 0.875rem;
    line-height: 140%;
    color: black;
}

.modal-dialogue {
    background: rgba(245, 242, 240, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
}

.modal-dialogue p {
    font-size: 0.875rem;
    line-height: 140%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.modal-dialogue p strong {
    font-weight: 500;
    padding-bottom: 10px;
}

.modal-cancel {
    font-size: 1rem;
    text-decoration: none;
    font-weight: 500;
}

.upcoming-dialogue {
    background: rgba(245, 242, 240, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
}

.upcoming-dialogue p {
    color: #525252;
    font-size: 0.875rem;
    line-height: 140%;
    padding-bottom: 0;
    margin-bottom: 0;
}

.upcoming-dialogue p.upcoming-date {
    color: #ff0000;
    font-weight: 500;
}


/* Guest Section Styles
---------------------------------------------------------------------------------------------- */

.guest-icon.date-icon {
    width: 52px;
    padding: 12px;
}

.guest-icon.reserved-icon {
    width: 36px;
    padding: 0 0 15px 0;
}

.guest-booking-list-item {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.select-bay-header {
    font-size: 1rem;
    font-weight: 500;
}

.bay-usage-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

ul.bay-usage-menu {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: 100%;
}

ul.bay-usage-menu li {
    position: relative;
    margin: 0;
}

ul.bay-usage-menu li:first-child {
    /* margin-left: 0; */
}

ul.bay-usage-menu li:last-child {
    /* margin-right: 0; */
}

ul.bay-usage-menu li a {
    display: block;
    width: 36px;
    height: 65px;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.3);
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 65px;
    text-align: center;
    font-weight: 500;
    color: #000000;
}

ul.bay-usage-menu li a.disabled,
ul.bay-usage-menu li a.disabled:hover,
ul.bay-usage-menu li a.disabled:active {
    display: block;
    width: 36px;
    height: 65px;
    background-color: #f6f6f6;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.3);
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 65px;
    text-align: center;
    font-weight: 500;
    color: #bbbaba;
}

ul.bay-usage-menu li a.enabled,
ul.bay-usage-menu li a:hover,
ul.bay-usage-menu li a:active {
    display: block;
    width: 36px;
    height: 65px;
    background-color: #009273;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.3);
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 65px;
    text-align: center;
    font-weight: 500;
    color: white;
}

.bay-usage-key-wrapper {
    margin-top: 30px;
}

p.bay-usage-key {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
}

p.bay-usage-key .key-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

p.bay-usage-key .key-icon.selected-bay-icon {
    background-color: #009273;
    border: 1px solid rgba(0,0,0,0.3);
}

p.bay-usage-key .key-icon.available-bay-icon {
    background-color: #ffffff;
    border: 1px solid rgba(0,0,0,0.3);
    margin-left: 10px;
}

p.bay-usage-key .key-icon.unavailable-bay-icon {
    background-color: #f6f6f6;
    border: 1px solid rgba(0,0,0,0.3);
    margin-left: 10px;
}

.unavailable-bay-alert {
    background-color: #fcc7ba;
    border: 1px solid #EF3F1C;
    color: #000000;
    border-radius: 4px;
    padding: 10px;
}

.unavailable-bay-alert p.bay-warning-text {
    color: #000000;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 140%;
    margin-bottom: 5px;
}

.unavailable-bay-alert p.bay-warning-text:last-child {
    margin-bottom: 0px;
}

.unavailable-bay-alert p.bay-warning-link-text {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 140%;
    display: block;
}

.unavailable-bay-alert .bay-warning-link {
    color: #ff0000;
    text-decoration: none;
}

.unavailable-bay-alert .bay-warning-link:after {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-right: 5px;
}

.unavailable-bay-alert .bay-warning-icon {
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

p.no-available-bays {
    font-size: 0.75rem;
    line-height: 140%;
    color: #525252;
    margin-top: 10px;
}

.calendarformModal {
    background-color: white;
    padding: 40px;
    border-radius: 4px;
    max-width: 1040px;
}

.modal-close-icon {
    display: block;
    width: 14px;
    height: 14px;
    background-image: url('assets/svg/ws-ui-icon-close-x-grey.svg');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center center;
    float: right;
}

.bay-calendar-wrapper {
    margin-top: 10px;
}

p.current-month {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 15px;
    color: #000000;
}

p.current-month .prev-month {
    display: inline-block;
    background-color: #ffffff;
    background-image: url('assets/svg/ws-sml-icon-chevronleft-dk-grey.svg');
    background-size: 6px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    width: 6px;
    height: 10px;
    margin-right: 30px;
}

p.current-month .next-month {
    display: inline-block;
    background-color: #ffffff;
    background-image: url('assets/svg/ws-sml-icon-chevronright-dk-grey.svg');
    background-size: 6px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    width: 6px;
    height: 10px;
    margin-left: 30px;
}

table#bay-calendar-availability {
    width: 100%;
    margin-bottom: 20px;
}

table#bay-calendar-availability th {
    height: 36px;
}

table#bay-calendar-availability th.bay-number,
table#bay-calendar-availability td.bay-number {
    font-size: 0.875rem;
    line-height: 140%;
    font-weight: normal;
    color: #525252;
    text-align: left;
    padding: 3px 20px 3px 0px;
}

table#bay-calendar-availability th.clock-hour,
table#bay-calendar-availability td.clock-hour {
    font-size: 0.75rem;
    line-height: 140%;
    font-weight: normal;
    color: #525252;
    text-align: center;
    padding: 3px 0px 3px 0px;
}

table#bay-calendar-availability td.bay-cell {
    padding: 3px 0px 3px 0px;
}

table#bay-calendar-availability td.bay-cell .bay-select {
    display: block;
    width: 34px;
    height: 34px;
    border: 1px solid #bbbaba;
    border-radius: 4px;
}

table#bay-calendar-availability td.bay-cell .bay-available {
    background-color: white;
    border: 1px solid #bbbaba;
}

table#bay-calendar-availability td.bay-cell .bay-unavailable {
    background-color: #bbbaba;
    border: 1px solid #bbbaba;
    background-image: url('assets/svg/ws-ui-icon-close-x-wht.svg');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: center center;
}

p.calendar-key {
    font-size: 0.875rem;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0;
    padding: 0;
}

p.calendar-key .key-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin: 0 5px;
}

p.calendar-key .key-icon.available-bay-icon {
    background-color: #ffffff;
    border: 1px solid #bbbaba;
}

p.calendar-key .key-icon.unavailable-bay-icon {
    background-color: #bbbaba;
    border: 1px solid #bbbaba;
    background-image: url('assets/svg/ws-ui-icon-close-x-wht.svg');
    background-size: 8px 8px;
    background-repeat: no-repeat;
    background-position: center center;
}



/* Settings Section styles
---------------------------------------------------------------------------------------------- */

.assigned-sub-section {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #e3e3e3;
}

.assigned-sub-section .MuiFormControl-marginDense {
    margin-bottom: 0 !important;
}

.assigned-sub-section .MuiInputBase-root {
    max-width: 420px;
    margin-bottom: 20px;
}

.assigned-parking-details {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.assigned-parking-details .assigned-edit-link {
    margin-left: auto;
}

.manage-assigned-wrapper {
    background: rgba(245, 242, 240, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px 15px 20px;
}

p.assigned-detail {
    font-size: 1rem;
    line-height: 140%;
}

p.assigned-detail strong {
    font-weight: 500;
    padding-right: 20px;
}

.assigned-edit-link {
    display: block;
    font-size: 0.875rem;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    color: #ff0000;
}

.assigned-edit-link:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-ui-icon-edit-red.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.add-vehilce-link {
    display: block;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    color: #ff0000;
}

.add-vehilce-link:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-ui-icon-add-red.svg');
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.radio-checked .MuiTypography-root {
    font-weight: 500;
}

.assigned-sub-section #vehicle-field {
    width: 348px !important;
}

.remove-upload {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-color: #979797;
    background-image: url('assets/svg/ws-ui-icon-close-x-wht.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
    margin: 18px 0 0 8px;
}

.recent-bookings-table {
    width: 100%;
    font-size: 0.75rem;
    line-height: 140%;
    font-weight: normal;
    color: #525252;
    text-align: left;
    margin-bottom: 20px;
}

.recent-bookings-table th {
    color: #000000;
    border-bottom: 1px solid #e3e3e3;
    padding: 14px 0;
    margin: 0;
}

.recent-bookings-table td {
    border-bottom: 1px solid #e3e3e3;
    padding: 15px 0;
    margin: 0;
}

.recent-bookings-table .booking-id {
    font-weight: 500;
    text-decoration: none;
}

.search-bookings-link {
    display: block;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    color: #ff0000;
}

.search-bookings-link:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-search-red.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 16px;
    width: 16px;
    margin-right: 7px;
}

.form-modal-styles.settings-modal p.input-label,
.self-reg-modal-styles p.input-label {
    color: #000000;
}

.form-modal-styles.settings-modal .MuiInputBase-root,
.self-reg-modal-styles .MuiInputBase-root {
    margin-bottom: 15px;
}

.download-sample {
    background: rgba(245, 242, 240, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.download-sample .download-desc-text p {
    font-size: 0.875rem;
    line-height: 140%;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 0;
    max-width: 312px;
}

.download-sample-link {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    color: #ff0000;
    margin-left: auto;
}

.download-sample-link:before {
    display: inline-flex;
    align-items: center;
    content: '';
    background-image: url('assets/svg/ws-ui-icon-download-red.svg');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 22px;
    width: 22px;
    margin-right: 7px;
}

.csv-icon {
    width: 44px;
    margin-right: 22px;
}

.upload-file-wrapper {
    max-width: 455px;
}

.upload-file-wrapper.uploaded {
    max-width: 100%;
}

.choose-file-link {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    line-height: 120%;
    font-weight: 500;
    text-decoration: none;
    color: #ff0000;
    margin-left: auto;
}

.choose-file-link:before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-ui-icon-browse-files-red.svg');
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 22px;
    width: 22px;
    margin-right: 7px;
    align-items: center;
}

.vert-centered {
    display: flex;
    align-items: center;
}

#upload-field {
    width: 505px !important;
}

.remove-vehicle {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-color: #979797;
    background-image: url('assets/svg/ws-ui-icon-close-x-wht.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
    margin-left: 20px;
}

.remove-upload,
.remove-vehicle {
    display: inline-flex;
    width: 24px;
    height: 24px;
    background-color: #979797;
    background-image: url('assets/svg/ws-ui-icon-close-x-wht.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 17px;
}



/* Utilisation Section styles
---------------------------------------------------------------------------------------------- */

h1.utilisation-h1 {
    padding-bottom: 20px;
}

p.summary-utilisation-key {
    font-size: 0.875rem;
    line-height: 200%;
    color: #525252;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

p.summary-utilisation-key .key-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

p.summary-utilisation-key .key-icon.assigned-utilisation-icon {
    background-color: #0085da;
    border: 1px solid #0085da;
}

p.summary-utilisation-key .key-icon.ondemand-utilisation-icon {
    background-color: #00436d;
    border: 1px solid #00436d;
    margin-left: 10px;
}

p.summary-utilisation-key .key-icon.guest-utilisation-icon {
    background-color: #55ddb4;
    border: 1px solid #55ddb4;
    margin-left: 10px;
}

.summary-data-h4 {
    font-size: 1.5rem;
    line-height: 120%;
    color: #000000;
}

.summary-data-h4.highlighted-h4 {
    color: #0085da;
}

.summary-data-text {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
}

.summary-graph-placeholder {
    max-width: 100%;
}

.ondemand-piechart-placeholder {
    max-width: 200px;
}

.users-barchart-placeholder {
    width: 100%;
    max-width: 385px;
}

table#utilisation-summary {
    width: 100%;
    font-size: 0.75rem;
    text-align: left;
}

table#utilisation-summary th,
table#utilisation-summary td {
    border-bottom: 1px solid #e3e3e3;
    padding: 15px 0;
}

table#utilisation-summary.ondemand-data th:first-child,
table#utilisation-summary.ondemand-data td:first-child {
    width: 60%;
}

table#utilisation-summary.user-data th:first-child,
table#utilisation-summary.user-data td:first-child {
    width: 45%;
}

table#utilisation-summary.team-data th:first-child,
table#utilisation-summary.team-data td:first-child {
    padding-right: 15px;
}

table#utilisation-summary.team-data th:nth-child(2),
table#utilisation-summary.team-data td:nth-child(2) {
    width: 45%;
}

table#utilisation-summary.team-data {
    margin-bottom: 40px;
}

table#utilisation-summary.team-data:last-child {
    margin-bottom: 0;
}

.daily-parking-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0085da;
    border: 1px solid #0085da;
    margin-right: 10px;
}

.hourly-parking-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #015e9a;
    border: 1px solid #015e9a;
    margin-right: 10px;
}

.download-instructions {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
}

.download-icon {
    width: 40px;
    padding-top: 30px;
}

.shuffle-users-tooltip {
    position: absolute;
    top: 122px;
    left: 138px;
    background-color: black;
    color: white;
    text-align: center;
    font-size: 0.75rem;
    padding: 8px 8px 10px 8px;
    border-radius: 3px;
}

.MuiTooltip {
    background-color: black !important;
    color: white;
    text-align: center;
    font-size: 0.75rem;
    padding: 8px 8px 10px 8px;
    border-radius: 3px;
}

.shuffle-mui-trigger .MuiButton-label {
    color: red !important;
}

.shuffle-mui-tooltip {

}

h5.team-name-h5 {
    font-size: 1.125rem;
    padding-bottom: 10px;
}

h5.team-name-h5 .team-key-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

h5.team-name-h5 .team-key-icon.light-blue {
    background-color: #0085da;
    border: 1px solid #0085da;
}

h5.team-name-h5 .team-key-icon.dark-blue {
    background-color: #00436d;
    border: 1px solid #00436d;
}


/* Home/Dashboard Section styles
---------------------------------------------------------------------------------------------- */

h1.dashboard-h1 {
    padding: 10px 0;
}

.dashboard-section h2 {
    font-size: 1.75rem;
}

.dash-section-card {
    background-color: white;
    border-radius: 4px;
    padding: 40px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    flex: 1;
}

.eq-height-col {
    display: flex;
    flex: 1;
}

.dash-section-card h3 {
    font-size: 1.5rem;
    line-height: 120%;
    padding-bottom: 10px;
}

.dash-section-card p.dash-card-text {
    font-size: 1rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 28px;
    display: block;
    min-height: 68px;
}

.dash-section-card ul.dash-card-links {
    list-style: none;
}

.dash-section-card ul.dash-card-links li {
    padding: 4px 0;
}

.dash-section-card ul.dash-card-links li a.link-text {
    font-size: 1rem;
    font-weight: 500;
    color: #ff0000;
    text-decoration: none;
}

.dash-section-card ul.dash-card-links li a.link-text:after {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 6px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 20px;
}

.dash-section-icon {
    height: 82px;
    margin-bottom: 22px;
}

.dash-book-guest {
    padding-left: 20px;
}

.content-panel h3.dash-data-h3 {
    font-size: 2.25rem;
    line-height: 120%;
}

.content-panel h4.dash-data-h4 {
    font-size: 1.5rem;
    line-height: 120%;
    text-align: right;
}

.content-panel h5.dash-data-h5 {
    font-size: 1.125rem;
    line-height: 120%;
}

.content-panel p.dash-data-text {
    font-size: 1rem;
    line-height: 140%;
    color: #525252;
}

.content-panel p.dash-data-text.align-right {
    text-align: right;
}

.content-panel p.dash-data-sml-text {
    font-size: 0.75rem;
    line-height: 140%;
    color: #525252;
}

.dash-more {
    text-decoration: none;
    color: #525252;
}

.dash-more:after {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-dk-grey.svg');
    background-size: 6px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 20px;
}

p.dash-data-sml-text .dash-more:after {
    width: 12px;
}

.dash-loading-panel {
    display: flex;
    justify-content: center;
}

.dash-loading-icon {
    margin: 100px 0;
}


/* Logged in section One Col layout styles
---------------------------------------------------------------------------------------------- */

.page-content-header-wrapper {
    margin-top: 130px;
    background-color: black;
    width: 100%;
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: center;
    position: absolute;
}

.page-content-header-wrapper.utilisation-section,
.page-content-header-wrapper.dashboard-section {
    position: relative;
}

.page-content-header-wrapper.dashboard-section {
    height: 300px;
}

.page-content-body-wrapper {
    position: relative;
    background: transparent;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.utilisation-section .full-width.top-section {
    max-width: 1031px; /* allows section to be 1015px wide */
    padding-top: 48px !important;
    padding-bottom: 120px !important;
}

.dashboard-section .full-width.top-section {
    max-width: 1146px; /* allows section to be 1015px wide */
    padding-top: 40px !important;
    padding-bottom: 120px !important;
}

.page-content-body-wrapper.dashboard-section {
    margin-top: -170px;
}

.page-content-body-wrapper.utilisation-section {
    margin-top: -88px;
}

.page-content-body-wrapper.guest-section {
    /* margin-top: -145px; */
    top: 170px;
}

.page-content-body-wrapper.settings-section {
    /* margin-top: -320px; */
    top: 170px;
}

.page-content-body-wrapper.assigned-section {
    /* margin-top: -220px; */
    top: 170px;
}

.page-content-body-wrapper.ondemand-section {
    /* margin-top: -280px; */
    top: 170px;
}

.page-content-body-wrapper.utilisation-section,
.page-content-body-wrapper.dashboard-section {
    top: 0px;
}

.utilisation-section .full-width.bottom-section {
    max-width: 1031px; /* allows section to be 1015px wide */
    top: 180px;
}

.dashboard-section .full-width.bottom-section {
    max-width: 1146px; /* allows section to be 1015px wide */
    top: 180px;
}

.two-column-inner-wrapper {
    max-width: 1440px;
    margin: 0 auto !important;
}




/* Logged in section Two Col layout styles
---------------------------------------------------------------------------------------------- */

#main.section-logged-in {
    max-width: 1360px;
    padding: 40px 40px 0 40px;
    margin: 130px auto 0 auto;
}

.left-col.sidebar {
    /* max-width: 280px;
    /* padding-right: 50px !important; */
    padding-top: 65px !important;
    padding-bottom: 65px !important;
    padding-left: 40px !important;
    padding-right: 35px !important; /* BASED ON SETTINGS SECTION PAGES */
    z-index: 2;
}

.left-col.sidebar h2 {
    color: #ffffff;
    font-size: 1.75rem;
    line-height: 120%;
    margin-bottom: 25px;
}

.left-col.sidebar h2 a.back-icon {
    text-decoration: none;
    color: #ffffff;
}

.on-demand-h2 img.icon-back-arrow {
    float: left;
    margin: 2px 10px 30px 0;
}

.left-col.sidebar h2.on-demand-h2.no-padding {
    margin-bottom: 0;
}

.left-col.sidebar p {
    color: #ffffff;
    font-size: 1rem;
    line-height: 140%;
    margin-bottom: 12px;
}

.content-panel {
    /*width: 935px;
    display: block;
    float: right; */
    background-color: #ffffff;
    padding: 40px;
    margin-bottom: 20px !important;
    border-radius: 4px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}

.content-panel.manage-users {
    width: 100%;
    position: absolute;
}

.content-panel-spacer {

}

.content-panel.larger-space {
    margin-bottom: 40px !important;
}

.content-panel.no-border-top {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 20px !important;
}

.right-col.position-absolute,
.position-absolute {
    position: absolute;
    right: 0;
    width: 100%;
}

.right-col.position-relative,
.position-relative {
    position: relative;
}

.right-col.button-wrapper {
    width: 1015px;
    display: block;
    float: right;
    padding: 0px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: right;
}

.content-panel h3 {
    font-size: 1.5rem;
    line-height: 120%;
    color: #000000;
    /* margin-bottom: 20px; */
}

.content-panel.manage-users h3 {
    padding-top: 23px;
}

.content-panel h3.padding-bottom-10 {
    padding-bottom: 10px;
}

.content-panel h3.padding-bottom-20 {
    padding-bottom: 20px;
}

.content-panel h3.padding-bottom-30 {
    padding-bottom: 30px;
}

.content-panel h3.padding-bottom-40 {
    padding-bottom: 40px;
}

.content-panel h3.padding-top-20 {
    padding-top: 20px;
}

.content-panel h3.padding-top-40 {
    padding-top: 40px;
}

.location-card {
    padding: 20px;
    background: rgba(245, 242, 240, 0.5);
    /* Wilson Global/Shades/10% */
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 0px;
    position: relative;
    float: left;
    width: 100%;
}

.location-card p.location-title {
    font-size: 1rem;
    line-height: 140%;
    font-weight: 500;
    color: #ff0000;
    margin-bottom: 10px;
}

.location-card p.domain-title {
    font-size: 1rem;
    line-height: 140%;
    font-weight: 500;
    color: #000000;
    margin-bottom: 10px;
}

.location-card .domain-url {
    margin-right: 20px;
}

.location-card .location-product-count {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
}

.icon-time, .icon-teams, .icon-location {
    display: inline-flex;
    margin-right: 10px;
}

.icon-time::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-time.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 16px;
    width: 16px;
    margin-right: 10px;
}

.icon-teams::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-team.svg');
    background-size: 18px 12px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 16px;
    width: 18px;
    margin-right: 10px;
}

.icon-location::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-location.svg');
    background-size: 12px 16px;
    background-repeat: no-repeat;
    background-position: left center;
    height: 16px;
    width: 12px;
    margin-right: 10px;
}

.close-card {
    position: absolute;
    top: 50%;
    right: 25px;
    width: 14px;
    height: 14px;
    margin-top: -7px;
}

label.select-heading,
.select-heading {
    color: #000000;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 140%;
    padding-bottom: 8px;
}

label.select-heading.white-text,
.select-heading.white-text {
    color: #ffffff;
}

.shuffle-select {
    padding: 5px 0 !important;
    margin-top: 8px;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8 {
    box-shadow: 0 0 7px 0px rgba(0,0,0,0.2);
    margin-top: 5px;
}

.MuiSelect-icon {
    width: 0.9em !important;
    height: 0.9em !important;
    color: #979797 !important;
}

.MuiSelect-select:focus {
    background-color: #ffffff !important;
}

.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: #f6f6f6 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #000000 !important;
    border-width: 1px !important;
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}

li.MuiMenuItem-root {
    font-size: 0.875rem !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    color: #525252;
}

.MuiMenu-list li.selectTitle {
    color: #000000;
}

.MuiMenu-list li.selectBold {
    font-weight: 500;
    color: #000000;
}

.MuiMenu-list li.selectLink {
    text-decoration: underline;
}

.MuiMenu-list li.selectBullet {
    padding-left: 25px;
}

.MuiMenu-list li:hover {
    text-decoration: underline;
    color: #000000;
    background-color: #f6f6f6;
}

table#transactions {
    width: 100%;
    text-align: left;
}

table#transactions th {
    font-size: 0.75rem;
    line-height: 140%;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #e3e3e3;
    padding: 12px 0;
}

table#transactions td {
    font-size: 0.75rem;
    line-height: 140%;
    color: #525252;
    border-bottom: 1px solid #e3e3e3;
    padding: 15px 0;
}

table#transactions td.transaction-id,
table#transactions a {
    color: #ff0000;
    font-weight: 500;
    text-decoration: none;
}

.inner-column-wrapper {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.inner-column-left {
    float: left;
}

.inner-column-right {
    float: right;
}

.pagination {
    float: left;
    width: 100%;
    text-align: center;
    margin: 50px 0 10px 0;
}

.pagination .pagination-link {
    font-size: 1rem;
    line-height: 140%;
    text-decoration: none;
    padding: 0 15px;
    color: #000000;
}
.pagination .pagination-link.current-page,
.pagination .pagination-link:hover,
.pagination .pagination-link:active {
    color: #ff0000;
    font-weight: 500;
    text-decoration: underline;
}

.tabs-panel {
    /* width: 1015px; */
    height: 73px;
    display: flex;
}

.tabs-panel .tab {
    float: left;
    height: 60px;
    border-bottom: 4px solid #000000;
    padding-top: 9px;
    padding-left: 17px;
    padding-right: 15px;
}

.tabs-panel .tab.pending-approval-tab {
    margin-left: 20px;
}

.tabs-panel .tab.selected-tab,
.tabs-panel .tab:hover,
.tabs-panel .tab:active {
    border-bottom: 4px solid #ff0000;
}

.tabs-panel .tab h4 {
    display: block;
    float: left;
    font-size: 1rem;
    line-height: 140%;
    color: #bbbaba;
}

.tabs-panel .tab.selected-tab h4 {
    color: #ffffff;
}

.tabs-panel .tab p {
    font-size: 0.875rem;
    line-height: 140%;
    color: #bbbaba;
}

.tab .tab-icon {
    float: left;
    margin-right: 17px;
    width: 32px;
}

.show-more-accordion,
.show-less-accordion,
.reset-all-filters {
    display: flex;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    height: 48px;
    line-height: 48px;
    margin-top: 28px;
}

.reset-all-filters.no-margin {
    margin-top: 0;
}

.show-more-accordion::after,
.show-less-accordion::after {
    display: inline-flex;
    content: "";
    background-size: 10px 6px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 6px;
    width: 10px;
    margin-left: 7px;
    margin-top: 21px;
}

.reset-all-filters::before {
    display: inline-flex;
    content: "";
    background-image: url("assets/svg/ws-sml-icon-refresh-red.svg");
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 15px;
    width: 15px;
    margin-right: 7px;
    margin-top: 16px;
}

.show-more-accordion::after {
    background-image: url("assets/svg/ws-sml-icon-chevrondown-red.svg");
}

.show-less-accordion::after {
    background-image: url("assets/svg/ws-sml-icon-chevronup-red.svg");
}

p.date-range-divider {
    padding: 0 10px;
}

/* Footer
---------------------------------------------------------------------------------------------- */

#footer {
    position: relative;
    width: 100%;
}

#footer.position-bottom {
    position: absolute;
    bottom: 0;
}

#footer.assigned-section {
    margin-top: 280px;
}

#footer.guest-section {
    margin-top: 240px;
}

#footer.ondemand-section {
    margin-top: 240px;
}

#footer.settings-section {
    margin-top: 240px;
}

#footer.dashboard-section {
    padding-top: 60px;
    background-color: #f6f6f6;
}

#footer.login-page,
#footer.landing-page {
    z-index: 0;
}

#footer-text p {
    color: #7C7C7E;
    padding: 20px 40px 40px 40px;
    font-size: 0.75rem;
}

#footer-text p a {
    color: #7C7C7E;
    text-decoration: none;
}

.login-page #footer-text p,
#footer.dark-bg #footer-text p {
    color: white;
}

.login-page #footer-text p a,
#footer.dark-bg #footer-text p a {
    color: white;
}



/* ----------------------------------------------------------------------------------------------

Media Queries

---------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 720px) {

    #self-reg-mobile-wrapper {
        /* top: 120px; */
        width: calc(100% - 80px);
        padding: 20px;
        margin: 0 auto;
        margin-top: 120px;
    }

    .MuiGrid-grid-xs-6 {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }

    .MuiGrid-grid-xs-9,
    .MuiGrid-grid-xs-3 {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media only screen and (max-width: 1519px) {
    #header,
    #header.main-nav,
    #header.login-nav {
        padding: 0 40px;
    }

    .top-nav-holder {
        width: calc(100% - 80px);
        padding: 0 40px;
    }
}

@media only screen and (max-width: 1095px) {

    .main-nav a#aust-resources-logo img {
        height: 24px;
    }

    .main-nav a#your-logo img {
        height: 24px;
    }

    ul#shuffle-main-nav li a {
        padding: 10px 12px;
    }

    .adminBG {
        height: auto !important;
    }

    .page-content-wrapper {
        background-color: white;
        width: 100%;
        /* background-image: url('assets/images/dashboard-bg-tile.png');
        background-repeat: repeat-x;
        background-position: top left; */
    }

    .page-content-header-wrapper {
        position: relative;
    }

    .page-content-wrapper.dashboard-section {
        background-color: #f6f6f6;
    }

    .page-content-v2 {
        margin-top: 130px;
        padding: 0 !important;
        padding-left: 0 !important;
    }

    .two-column-inner-wrapper {
        width: 100% !important;
    }

    .left-col.sidebar {
        /* display: flex; */
        max-width: 100%;
        width: 100%;
        flex-basis: 100%;
        padding: 65px 40px 162px 40px !important;
    }

    .left-col.sidebar h2 {
        width: 100%;
    }

    .left-col.sidebar h2 br {
        display: none;
    }

    .page-content-body-wrapper {
        top: -135px !important;
    }

    .page-content-body-wrapper .MuiGrid-container.MuiGrid-spacing-xs-2 {
        width: 100% !important;
        margin: 0 !important;
    }

    .right-col.position-absolute, .position-absolute {
        position: relative;
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 40px !important;
        padding-right: 40px !important;
        margin-top: -143px;
    }

    .right-col {
        max-width: 100% !important;
        flex-basis: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .mobile-left-col-wrapper {
        margin: 0;
        box-sizing: border-box;
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .mobile-right-col-wrapper {
        margin: 0;
        box-sizing: border-box;
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    /* Guest section modal */

    table#bay-calendar-availability td.bay-cell .bay-select {
        width: 20px;
        height: 20px;
    }

    table#bay-calendar-availability th.bay-number, table#bay-calendar-availability td.bay-number {
        padding-right: 3px;
    }

    .calendarformModal {
        max-width: calc(100% - 160px);
    }

    #footer {
        position: relative;
        margin-top: 0 !important;
    }

    /* Responsive wrappers */

    .responsive-title-wrapper {
        width: 100% !important;
    }

    .responsive-content-wrapper {
        width: 70% !important;
        float: left;
    }

    .responsive-content-wrapper br {
        display: none;
    }

    .responsive-content-wrapper.full-width {
        width: 100% !important;
        float: left;
    }

    .responsive-button-wrapper {
        width: 30% !important;
        float: left;
    }

    .responsive-content-wrapper .MuiFormControl-root.MuiFormControl-marginDense {
        margin: 0 0 0 0;
        max-width: 285px;
        margin-right: 20px;
    }

    .responsive-button-wrapper .MuiButtonBase-root {
        margin-top: 0 !important;
    }

    .responsive-button-wrapper.right-aligned {
        text-align: right;
    }

    .responsive-table-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        max-width: 600px;
        margin-bottom: 0;
    }

    .assigned-parking-table.table-section-one {
        width: 50%;
    }

    .assigned-parking-table.table-section-two {
        width: 25%;
    }

    .assigned-parking-table.table-section-three {
        width: 25%;
    }

    #assigned-parking-table td:first-child,
    .assigned-parking-table td:first-child {
        padding: 5px 5px 5px 0;
    }

    .utilisation-section .full-width.top-section {
        max-width: 100%;
        width: 100%;
        padding-top: 48px !important;
        padding-bottom: 120px !important;
        padding-left: 48px;
        padding-right: 48px;
    }

    .utilisation-section .full-width.bottom-section {
        max-width: 100%;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .dashboard-section .full-width.top-section {
        max-width: 100%;
        width: 100%;
        padding-top: 40px !important;
        padding-bottom: 120px !important;
        padding-left: 40px;
        padding-right: 40px;
    }

    .dashboard-section .full-width.bottom-section {
        max-width: 100%;
        width: 100%;
        padding: 0 !important;
    }

    .page-content-header-wrapper.dashboard-section .MuiGrid-root.MuiGrid-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .page-content-body-wrapper.dashboard-section {
        margin-top: -36px;
    }

    .page-content-body-wrapper.utilisation-section {
        margin-top: 55px;
    }

    .page-content-body-wrapper .MuiGrid-container.MuiGrid-spacing-xs-2.three-columns-responsive {
        margin-left: -8px !important;
        margin-right: -8px !important;
        width: calc(100% + 16px) !important;
    }

    .content-panel .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-10,
    .content-panel .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2 {
        padding: 0 !important;
    }

    .content-panel .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-10 .MuiGrid-root.MuiGrid-container .MuiGrid-item.MuiGrid-grid-xs-4 {
        padding-left: 0 !important;
    }

    /* #assigned-parking-table,
    .assigned-parking-table {
        display: flex;
        flex-direction: column;
    }

    #assigned-parking-table tr,
    .assigned-parking-table tr {
        float: left;
        width: 30%;
    }

    .mobile-wrapper-12 {
        float: left;
        width: 100%;
    }

    .mobile-wrapper-9 {
        float: left;
        width: 75%;
    }

    .mobile-wrapper-6 {
        float: left;
        width: 50%;
    }

    .mobile-wrapper-6 .MuiFormControl-root.MuiTextField-root,
    .mobile-wrapper-6 .MuiButtonBase-root.MuiButton-root {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .mobile-wrapper-6 .MuiFormControl-root.MuiTextField-root {
        max-width: 310px;
    }

    .mobile-wrapper-6 .MuiButtonBase-root.MuiButton-root {
        max-width: 140px;
    }

    .mobile-wrapper-6 .MuiGrid-root.MuiGrid-container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .mobile-wrapper-3 {
        float: left;
        width: 25%;
    } */

}

@media only screen and (max-width: 1200px) {

    /* On-demand Landing page */

    .section-card {
        width: calc(33% - 105px);
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 1040px) {

    ul#shuffle-main-nav li a {
        font-size: .875rem;
    }

    .main-nav a#aust-resources-logo img {
        height: 20px;
    }

    .main-nav a#your-logo img {
        height: 20px;
    }

    .logo-holder.row {
        width: 27%;
    }

    .nav-holder.row {
        width: 73%;
    }

}

@media only screen and (min-width: 1919px) {

    #header.main-nav {
        padding: 0px 40px;
    }

    ul#shuffle-top-nav,
    ul#shuffle-home-nav,
    .top-nav-holder,
    #header.main-nav {
        max-width: 1840px;
    }

    .top-nav-holder {
        width: 100%;
    }

}

@media only screen and (min-width: 1096px) and (max-width: 1439px) {

    .page-content-header-wrapper {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .left-col.sidebar {
        padding-left: 0 !important;
        padding-right: 40px !important;
    }

    .responsive-button-wrapper {
        margin-top: 0px !important;
    }

    .responsive-button-wrapper .MuiGrid-grid-xs-6 {
        width: 100% !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
    }

    .responsive-button-wrapper .MuiGrid-grid-xs-6 .MuiButtonBase-root.MuiButton-root {
        width: 100% !important;
        max-width: 100% !important;
        margin-top: 0px !important;
        margin-bottom: 0 !important;
    }

    .content-panel .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6:first-of-type {
        padding-right: 20px;
    }

    .content-panel .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6:last-of-type {
        padding-left: 20px;
    }

    /* .left-col.sidebar {
        padding-left: 0px !important;
        padding-right: 0px !important; /* was 40px - do we need to reinstate?
    } */

}

@media only screen and (min-width: 1096px) {

    .left-col.sidebar p:last-child {
        margin-bottom: 34px;
    }
    .inputOtpStyle {
        width: 30px !important;
    }
    .inputOtpStyleError {
        width: 30px !important;
    }

}

@media only screen and (min-width: 1441px) {

    #footer {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }
    .inputOtpStyle {
        width: 40px !important;
    }
    .inputOtpStyleError {
        width: 40px !important;
    }
}

@media only screen and (max-width: 1024px) {

    .page-content-wrapper {
        width: 1024px;
        max-width: 1024px;
        overflow-x: scroll;
    }
    .inputOtpStyle {
        width: 30px !important;
    }
    .inputOtpStyleError {
        width: 30px !important;
    }
}

@media only screen and (max-height: 830px) {

    .loginBG {
        height: auto !important;
    }

    .loginBG .MuiBox-root {
        position: relative !important;
    }

    .inputOtpStyle {
        font-size: 14px !important;
        width: 25px !important;
        margin:2px !important;
    }
    .inputOtpStyleError {
        font-size: 14px !important;
        width: 25px !important;
        margin:2px !important;
    }

}

@media only screen and (min-width: 375px){

    .inputOtpStyle {
        font-size: 14px !important;
        width: 25px !important;
        margin:3px !important;
    }
    .inputOtpStyleError {
        font-size: 14px !important;
        width: 25px !important;
        margin:2px !important;
    }
}


@media only screen and (max-width: 500px) {
    .inputOtpStyle {
        font-size: 14px !important;
        width: 25px !important;
        margin:3px !important;
    }
    .inputOtpStyleError {
        font-size: 14px !important;
        width: 25px !important;
        margin:2px !important;
    }
}


.self-reg-modal-styles p.password-tips {
    display: block;
    width: 100%;
    text-transform: uppercase;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 5px;
    margin-top: 15px;
}

.self-reg-modal-styles ul.password-tips-list {
    font-size: 0.875rem;
    line-height: 140%;
    color: #525252;
    margin-bottom: 10px;
    width: 100%;
    padding-left: 20px;
}

.self-reg-modal-styles ul.password-tips-list.password-accepted {
    color: #009273;
}

.self-reg-modal-styles ul.password-tips-list li {
    list-style-image: url(assets/svg/ws-sml-icon-tick-grey.svg);
    padding: 5px 0 5px 10px;
    text-align: left;
}



.self-reg-modal-styles ul.password-tips-list li.password-accepted{
    color: #009273;
    list-style-image: url(assets/svg/ws-sml-icon-tick-grn.svg);
}


.MuiFormHelperText-root {
    margin-left: 4px !important;
    margin-top: -14px !important;
    color: #ef3f1c !important;
    font-size: 0.75rem;
}

.self-reg-icon-not-found {
    width: 705px;
    padding-top: 30px;
    padding-bottom: 50px;
}


.inputOtpStyle {
    width: 30px;
    height: 3rem;
    margin: 0 8px;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.inputOtpStyleError {
    color: #ff0000;
    width: 30px;
    height: 3rem;
    margin: 0 8px;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid #ff0000;
}

.self-reg-sms-error-text {
    color: #ff0000;
    font-size: 0.7rem;
    margin-top: 4px;
    text-align: center;
}

.containerOtpStyle {
    display: inline-flex !important;
}

.self-reg-modal-styles div.resend-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #FF0000;
    display: block;
    margin-left: -20px;
    margin-top: 16px;
    cursor: pointer;
}

.self-reg-modal-styles div.email-sent {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #FF0000;
    display: block;
    margin-left: -20px;
    margin-top: 8px;
    cursor: pointer;
}

.self-reg-modal-styles div.resend-link::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-right: 5px;
}

div.resend-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #FF0000;
    display: block;
    margin-left: -20px;
    margin-top: 16px;
    cursor: pointer;
}

div.resend-link::before {
    display: inline-flex;
    content: '';
    background-image: url('assets/svg/ws-sml-icon-chevronright-red.svg');
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 10px;
    width: 17px;
    margin-right: 5px;
}
