.hosted-field-group {
    position: relative;
    text-align: left
}

.hosted-field-label {
    font-size: 14px
}

.hosted-field-label.required::after {
    content: '*';
    color: #009fe3;
    margin-left: 4px;
    display: inline-block
}

.hosted-field {
    border-radius: 0;
    border: 1px solid #ccc;
    padding: 0;
    margin: 5px 0 20px 0;
    height: 48px;
    width: 100%;
    position: relative;
    line-height: 20px
}

.hosted-field-focus {
    border-radius: 3px;
    border: 1px solid black;
    box-shadow: 0 0 0 1px black
}

.hosted-field-invalid {
    border: 1px solid #d83333
}

.hosted-field-valid {
    border: 1px solid #009fe3
}

.hosted-field-error-message-visible {
    transform: translateX(-50%);
    font-size: 12px;
    margin: 0px auto 0;
    background: rgba(216, 51, 51, .9);
    border-radius: 6px;
    position: absolute;
    left: 50%;
    z-index: 100;
    padding: 6.67px 13.33px;
    line-height: 1.2em;
    text-align: center;
    color: #fff;
    display: block;
    top: 100%
}

.hosted-field-error-message-visible::after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: rgba(216, 51, 51, .9);
    border-width: 10px;
    margin-left: -10px
}

.hosted-field-error-message-hidden {
    display: none
}